<template>
  <div class="view pa24">
    <div class="d-flex mb20">
      <div class="bgf7fa pt20 pr20 pl20 pb20 d-flex flex-column mr20 w600">
        <p>成员名称：{{memberInfo.name}}</p>
        <p>下级合作伙伴数：{{memberInfo.partnerNum}}</p>
        <p>转介绍客户总数：{{memberInfo.customerNum}}</p>
        <p>已成交客户数：{{memberInfo.customerNum}}</p>
        <p>成交总金额：￥{{memberInfo.shopSell + memberInfo.manualSell}}</p>
        <p>推广赏金支出金额：￥{{memberInfo.partnerRate + memberInfo.partnerRateWait}} </p>
      </div>
      <div class="bgf7fa pt20 pr20 pl40 d-flex  mr20 w600">
        <div class="d-flex flex-column align-items-center pt20 mr100">
          <div class="mb20">成员邀请码</div>
          <div class="d-flex fwbold align-items-center cA1 fs8">
            {{memberInfo.partnerKey || '暂无'}}
            <i class="el-icon-document-copy ml20 " @click="copyTemplate(memberInfo.partnerKey)"></i>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center pt18">
          <div class="mb18">邀请二维码</div>
          <div class="d-flex cblue fs8 hover_pointer" @click="queryCode">
            点击查看
          </div>
        </div>
      </div>
    </div>
    
    <div class="d-flex align-items-center">
      合作伙伴名称：<el-input class="mr20 w200"  v-model="keyword" placeholder="请输入合作伙伴名称"></el-input>
      手机号：<el-input class="mr20 w200"  v-model="phone" placeholder="请输入手机号"></el-input>
      <el-date-picker
        v-model="pickerValue"
        class="mr10 "
        align="right"
        value-format="yyyy-MM-dd"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
      
      <el-button type="primary" @click="queryVisitorsData">查询</el-button>
      <!-- <el-button type="primary" @click="queryVisitorsData">导出</el-button> -->
    </div>
    
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
            prop="name"
            align="center"
            label="合作伙伴名称"
            show-overflow-tooltip
          />
        <el-table-column prop="phone" align="center" label="手机号" />
        <el-table-column prop="createdTime" show-overflow-tooltip align="center" label="成为合作伙伴时间" />
        <el-table-column prop="customerNum" align="center" label="转介绍客户总数" />
        <el-table-column prop="notTrading" align="center" label="未跟进客户数" />
        <el-table-column prop="customerFollowNum" align="center" label="已跟进客户数" />
        <el-table-column prop="customerSuccNum" align="center" label="已成交客户数" />
        <el-table-column prop="manualSell" align="center" label="录入成交金额" />
        <el-table-column prop="shopSell" align="center" label="商城交易金额" />
        <el-table-column prop="partnerRate" align="center" label="累计获得推广赏金" />
        <el-table-column prop="balance" align="center" label="可提现赏金" />
        <el-table-column prop="withdrawalsWait" align="center" label="提现中赏金" />
        <el-table-column prop="visitTime" width="150" align="center" label="操作">
          <template slot-scope="scope">
            <div class="d-flex">
              <p class="cred hover_pointer mr20" @click="releaseRelationship(scope.row)">解除关系</p>
              <p class="cblue hover_pointer" @click="toDetailPage(scope.row)">详情</p>
            </div>
            
          </template>
        </el-table-column>
        
      </template>
    </commonTable>
    <el-dialog title="邀请二维码" 
               width="20%"
               :center="true"
               :modal-append-to-body="false"
               :visible="QrCodeVisible"
               @close="dialogClose">
         <img :src="QrCodeImg" class="w100p h100p">
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec,copyText } from "@/utils";
import {  
  queryPartnerList,
  queryInvitationCode,
  employeeDetails,
  dismiss
} from "@/api/turnToIntroduce";
 
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      staffs: [],
      userId: "",
      sourcesId: "",
      authsId: "",
      phone: "",
      startTime: this.startTime,
      endTime: this.endTime,
      totalSells:0, //收入合计
      totalPrice:0, //累计商品收入
      totalCommission:0 ,//累计佣金
      keyword:'',
      memberInfo:"",
      QrCodeVisible:false,
      QrCodeImg:'',
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    console.log(this.$route.query.id)
    this.queryVisitorsData()
    this.employeeDetails()
  },
  methods: {
    async queryVisitorsData() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        empId:this.$route.query.id,
        phone: this.phone,
        name:this.keyword,
        startTime: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        endTime: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
      };
      
      try {
        this.loading = true;
        const result = await queryPartnerList(data);
        this.loading = false;
        const { total, list } = result?.data?.pageInfo;
        this.tableData = list;
        this.tableData.forEach(item=>{
          item.notTrading = item.customerNum - item.customerFollowNum
          //item.aggregateAmount = item.manualSell + item.shopSell
          item.moneyReward = item.partnerRate + item.partnerRateWait
        })
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
        console.log(error);
      }
    },
    copyTemplate(key){
      if(!key){
        return
      }
      copyText(key);
      this.$message.success('复制成功！');
    },
    // formatSeconds(value) {
    //   var theTime = parseInt(value);// 秒
    //   var theTime1 = 0;// 分
    //   var theTime2 = 0;// 小时
    //   if(theTime > 60) {
    //     theTime1 = parseInt(theTime/60);
    //     theTime = parseInt(theTime%60);
    //     if(theTime1 > 60) {
    //       theTime2 = parseInt(theTime1/60);
    //       theTime1 = parseInt(theTime1%60);
    //     }
    //   }
    //   var result = ""+parseInt(theTime)+"秒";
    //   if(theTime1 > 0) {
    //     result = ""+parseInt(theTime1)+"分"+result;
    //   }
    //   if(theTime2 > 0) {
    //     result = ""+parseInt(theTime2)+"小时"+result;
    //   }
    //   return result;
    // },
    employeeDetails(){
      let data = {
        empId:this.$route.query.id
      }
      employeeDetails(data)
      .then(res=>{
        console.log(res)
        if(res && res.data){
          this.memberInfo = res.data
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
    toDetailPage(item){
      this.$router.push({ path:`/partnerListDetail?empId=${item.empId}&userId=${item.userId}&epId=${item.epId}` });
    },
    dialogClose(){
      this.QrCodeVisible = false
    },
    releaseRelationship(item){
      let than
      this.$confirm('解除关系后，所有转介绍客户的内容都将清除，且不可恢复，是否继续', '解除关系', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let data ={
          empId:item.empId,
          userId:item.userId,
        }
        dismiss(data)
        .then(res=>{
          if(res.code == 200){
            this.$message.success('成功取消') 
            this.queryVisitorsData()
          }
        })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });          
      });
    },
    queryCode(){
      let data = {
        userId:this.memberInfo.userId
      }
      queryInvitationCode(data)
      .then(res=>{
        this.QrCodeImg = res.data
        if(this.QrCodeImg){
          this.QrCodeVisible = true
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>